<template>
  <div id="loginForm">
    <div class="login-inner">
      <div class="login-l">
        <div class="login-l-inr">
          <div class="login-logo-w">
            <a href="/">
              <img src="../assets/images/redline-white-logo.svg" alt="" />
            </a>
          </div>
          <div class="login-l-middle">
            <h2>{{ $t("login.heading") }}</h2>
          </div>
        </div>
      </div>
      <div class="login-r">
        <div class="login-box">
          <div></div>
          <div class="login-form">
            <div class="logo-icon">
              <img src="../assets/images/ri_user-line.svg" />
            </div>
            <h2>{{ $t("login.login_form_title") }}</h2>
            <v-form id="login">
              <div class="form-group">
                <label for="exampleInputText">{{ $t("login.username") }}</label>
                <v-text-field hide-details="auto" v-model.trim="email" :error-messages="emailError"
                  :placeholder="$t('login.username')" @blur="$v.email.$touch()" outlined
                  append-icon="mdi-account-outline" class="mb-4" name="email"></v-text-field>
              </div>
              <div class="form-group">
                <label for="exampleInputPassword1">{{ $t("login.password") }}</label>
                <v-text-field hide-details="auto" v-model.trim="password" :error-messages="passwordError"
                  type="password" @blur="$v.password.$touch()" outlined :placeholder="$t('login.password')"
                  append-icon="mdi-lock-outline" class="mb-4" name="password"></v-text-field>
              </div>
              <v-btn @click="submit" block color="btn custom-btn pattern-btn" dark x-large>
                <span>{{ $t("login.login") }}</span>
              </v-btn>
            </v-form>
          </div>
          <div class="login-r-btm">
            <div class="phone-dtl">
              <a href="tel:+1 714 334 5505">
                <img src="../assets/images/icon-phone-call-green.svg" />
                <p>
                  {{ $t("login.helpCall") }}
                  <b>714-334-5505</b>
                </p>
              </a>
            </div>
            <div class="social-dtl">
              <p>{{ $t("login.social") }} </p>
              <ul>
                <li>
                  <a :href="VUE_APP_RDL_INVENTORY_LINK" target="_blank">
                    <img src="../assets/images/union-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_FACEBOOK_LINK" target="_blank">
                    <img src="../assets/images/fb-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_INSTAGRAM_LINK" target="_blank">
                    <img src="../assets/images/insta-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_WHATSAPP_LINK" target="_blank">
                    <img src="../assets/images/whatsapp-green.svg" />
                  </a>
                </li>
                <li>
                  <a :href="VUE_APP_RDL_YOUTUBE_LINK" target="_blank">
                    <img src="../assets/images/youtube-green.svg" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <v-dialog v-model="dialog" width="500" content-class="login-modal">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ $t("login.forceLogoutDialogTitle") }}
          </v-card-title>

          <v-card-text>
            <div>
              <p>
                {{ $t("login.forceLogoutDialogMessage") }}
              </p>
            </div>
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn height="50" medium @click="dialog = false" dense class="btn custom-btn red-outline-btn"
              elevation="0">
              {{ $t("login.cancel") }}
            </v-btn>

            <v-btn height="50" medium @click="forceLogin" dense class="btn custom-btn pattern-btn"
              elevation="0">
              {{ $t("login.forceLogout") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog persistent v-model="paymentDialog" width="500" content-class="login-modal">
        <v-card>
          <v-card-title class="headline grey lighten-2">
            {{ $t("login.payment_information") }}
          </v-card-title>
          <v-card-text>
            <div>
              <p>
                {{ $t("login.due_pay_text") }}
              </p>
            </div>
          </v-card-text>


          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn height="50" medium @click="doLogout" dense class="btn custom-btn pattern-btn"
              elevation="0">
              {{ $t("login.logout") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import { RECURRING_BY_CARD, MONTHLY_BY_CARD } from "@/constants/common";

export default {
  name: "Login",
  components: {
    Sidebar: () => import("@/components/PublicAside"),
  },
  data() {
    return {
      VUE_APP_FACEBOOK_LINK: process.env.VUE_APP_FACEBOOK_LINK,
      VUE_APP_INSTAGRAM_LINK: process.env.VUE_APP_INSTAGRAM_LINK,
      VUE_APP_RDL_INVENTORY_LINK: process.env.VUE_APP_INVENTORY_URL,
      VUE_APP_RDL_YOUTUBE_LINK: process.env.VUE_APP_YOUTUBE_LINK,
      VUE_APP_RDL_WHATSAPP_LINK: process.env.VUE_APP_WHATSAPP_LINK,
      email: "",
      password: "",
      force_login: false,
      dialog: false,
      paymentDialog: false,
      apiErrorMessage: null,
      loggedInMessage: null,
      errors: {
        email: null,
        password: null,
      },
    };
  },
  validations: {
    email: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    ...mapGetters({
      userInfo: "user/getUser",
    }),
    emailError() {
      let error = [];
      if (this.$v.email.$dirty && !this.$v.email.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("login.email"),
        });
      }
      if (this.errors && this.errors.email) {
        error = this.errors.email;
      }

      return error;
    },
    passwordError() {
      let error = "";
      if (this.$v.password.$dirty && !this.$v.password.required) {
        error = this.$t("requiredValidation", {
          field: this.$t("login.password"),
        });
      }
      if (this.errors && this.errors.password) {
        error = this.errors.password;
      }

      return error;
    },
  },

  methods: {
    ...mapActions({
      login: "user/login",
      logout: "user/logout",
      user: "user/getUser",
    }),
    async submit() {
      this.$v.$touch();
      this.dialog = false;
      if (this.email && this.password) {
        this.$store.commit("setLoader", true);
        try {
          await this.login({
            email: this.email,
            password: this.password,
            force_login: this.force_login,
          });
          if (this.userInfo && this.userInfo.is_new_user && !this.userInfo.is_subscribed) {
            await this.logout();
            sessionStorage.removeItem("onlineUsers");
            this.paymentDialog = true;
          } else if (
            this.userInfo &&
            !this.userInfo.is_subscribed &&
            !this.userInfo.is_on_grace_period
          ) {
            await this.logout();
            sessionStorage.removeItem("onlineUsers");
            this.paymentDialog = true;
          } else if (
            this.userInfo &&
            this.userInfo.has_failed_payment &&
            !this.userInfo.is_on_grace_period &&
            [RECURRING_BY_CARD, MONTHLY_BY_CARD].includes(
              this.userInfo.payment_method
            )
          ) {
            await this.logout();
            sessionStorage.removeItem("onlineUsers");
            this.paymentDialog = true;
          } else {
            this.$router.push({ name: "Dashboard" });
          }
        } catch ({ message }) {
          if (message.logged_in) {
            this.loggedInMessage = message.logged_in[0];
            this.dialog = true;
          }
          this.errors.email = message.email;
          this.errors.password = message.password;
        } finally {
          this.$store.commit("setLoader", false);
        }
      }
    },

    forceLogin() {
      try {
        this.force_login = true;
        this.submit();
      } finally {
        this.force_login = false;
        this.dialog = false;
      }
    },

    async doLogout() {
      try {
        await this.logout();
        sessionStorage.removeItem("onlineUsers");
        window.location.reload();
      } catch ({ message }) {
        console.log(message);
      }
    },
  },
};
</script>
